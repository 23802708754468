'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const defaultOptions = {
  classes: {
    hold: "v-touch-hold",
    press: "v-touch-press",
    multi: "v-touch-multi",
    dbltap: "v-touch-dbltap",
    tap: "v-touch-tap",
    longtap: "v-touch-longtap",
    hover: "v-touch-hover",
    leave: "v-touch-leave",
    rollover: "v-touch-rollover",
    swipe: "v-touch-swipe",
    drag: "v-touch-drag",
    dragstart: "v-touch-dragstart",
    release: "v-touch-release"
  },
  tolerance: {
    tap: 2,
    multi: 50,
    dbltap: 250,
    longtap: 200,
    hold: 500,
    timeout: 200,
    debounce: 25,
    drag: 10,
    swipe: 10
  }
};
const defaultFlags = {
  currentXY: [],
  startXY: [],
  lastXY: [],
  shiftXY: [],
  multi: 1,
  touchStarted: false,
  touchMoved: false,
  scale: 0,
  scroll: [0, 0],
  touchStartTime: void 0,
  requestAnimationFrameId: void 0,
  touchDbltapTimer: void 0,
  touchHoldTimer: void 0,
  touchRolloverTimer: void 0,
  touchDragTimer: void 0
};
const isTouchScreenDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints;
};
const assignOptions = (defaults, options) => Object.assign({}, defaultOptions, defaults || {}, options || {}, { classes: Object.assign({}, defaultOptions.classes, defaults?.classes || {}, options?.classes || {}) }, { tolerance: Object.assign({}, defaultOptions.tolerance, defaults?.tolerance || {}, options?.tolerance || {}) });
const getTouchCoords = (event, el, type) => {
  if (event.type.indexOf("mouse") !== -1 || event.type.indexOf("click") !== -1 || event.type.indexOf("scroll") !== -1) {
    return {
      x: event.clientX,
      y: event.clientY,
      length: 1,
      distance: 0
    };
  } else {
    let distance = 0;
    const length = event.touches.length;
    const showLangth = type && ["tap", "release", "longtap"].includes(type) ? length + 1 : length;
    if (!length) {
      return {
        x: el._vueTouch.lastXY[0],
        y: el._vueTouch.lastXY[1],
        length: showLangth,
        distance: el._vueTouch.lastDistance
      };
    }
    const x = event.touches[0].clientX;
    const y = event.touches[0].clientY;
    if (length > 1) {
      const last = event.touches[length - 1];
      const x1 = last.clientX;
      const y1 = last.clientY;
      distance = Math.sqrt(Math.pow(x1 - x, 2) + Math.pow(y1 - y, 2));
    }
    return {
      x,
      y,
      length: showLangth,
      distance
    };
  }
};
const addClass = (el, name, autoRemove = false) => {
  const className = el._vueTouch.opts.classes[name];
  className && el.classList.add(className);
  autoRemove && setTimeout(() => removeClass(el, name), el._vueTouch.opts.tolerance.timeout);
};
const removeClass = (el, name) => {
  const className = el._vueTouch.opts.classes[name];
  className && el.classList.remove(className);
};
const clean = (el) => {
  const classes = el._vueTouch.opts.classes || {};
  Object.keys(classes).map((cl) => removeClass(el, cl));
  Object.assign(el._vueTouch, defaultFlags);
};
const emit = (event, el, type, countCoords = true) => {
  countCoords && setXYLD(event, el, type);
  const callbacks = el._vueTouch.callbacks.filter((cl) => cl.arg === type);
  for (const binding of callbacks) {
    binding.modifiers.stop && event.stopPropagation();
    binding.modifiers.prevent && event.preventDefault();
    if (binding.modifiers.self && event.target !== el) {
      continue;
    }
    if (typeof binding.value === "function") {
      if (binding.modifiers.multi && el._vueTouch.multi <= 1) {
        continue;
      }
      if (type && ["drag", "swipe"].includes(type)) {
        const filters = ["left", "right", "top", "bottom"].filter((v) => binding.modifiers[v]);
        if (filters.length && el._vueTouch.direction) {
          if (!filters.includes(el._vueTouch.direction)) {
            continue;
          }
        }
      }
      const addition = Object.fromEntries(Object.entries(el._vueTouch).filter(([key]) => [
        "direction",
        "currentXY",
        "multi",
        "shiftXY",
        "scale",
        "scroll"
      ].includes(key)));
      binding.value({
        originalEvent: event,
        type,
        ...addition
      });
    }
  }
};
const createTouchElement = (el, options) => {
  return Object.assign(el, {
    _vueTouch: {
      ...defaultFlags,
      callbacks: [],
      ..."_vueTouch" in el ? el._vueTouch : {},
      opts: assignOptions(options, "_vueTouch" in el ? el._vueTouch.opts : {})
    }
  });
};
const getShifts = (el) => {
  const box = el.getBoundingClientRect();
  return [box.left, box.top];
};
const setXYLD = (event, el, type) => {
  const vt = el._vueTouch;
  vt.lastXY = vt.currentXY;
  vt.lastDistance = vt.distance;
  const { x, y, length, distance } = getTouchCoords(event, el, type);
  vt.currentXY = [x, y];
  vt.multi = length > 1 && distance > vt.opts.tolerance.multi ? length : 1;
  vt.distance = distance;
  vt.scroll = [el.scrollLeft, el.scrollTop];
  if (vt.lastDistance && vt.distance) {
    if (vt.lastDistance > vt.distance) {
      vt.scale = -1;
    }
    if (vt.lastDistance < vt.distance) {
      vt.scale = 1;
    }
    if (vt.lastDistance === vt.distance) {
      vt.scale = 0;
    }
  } else {
    vt.scale = 0;
  }
  if (vt.startXY.length) {
    const modX = Math.abs(vt.currentXY[0] - vt.startXY[0]);
    const modY = Math.abs(vt.currentXY[1] - vt.startXY[1]);
    if (modX > modY) {
      vt.direction = vt.currentXY[0] < vt.startXY[0] ? "left" : "right";
    } else {
      vt.direction = vt.currentXY[1] < vt.startXY[1] ? "top" : "bottom";
    }
  } else {
    vt.direction = void 0;
  }
  if (el) {
    const coords = getShifts(el);
    vt.shiftXY = [vt.currentXY[0] - coords[0], vt.currentXY[1] - coords[1]];
  }
};

const touchstart = (event) => {
  const el = event.currentTarget;
  const vt = el._vueTouch;
  vt.touchStarted = true;
  vt.touchMoved = false;
  vt.touchStartTime = event.timeStamp;
  emit(event, el, "press");
  addClass(el, "press", true);
  vt.multi > 1 && addClass(el, "multi", true);
  vt.touchHoldTimer = vt.touchHoldTimer || setTimeout(() => {
    emit(event, el, "hold");
    addClass(el, "hold");
  }, vt.opts.tolerance.hold);
};
const touchmove = (event) => {
  const el = event.currentTarget;
  const vt = el._vueTouch;
  if (!vt.touchStarted) {
    return;
  }
  vt.requestAnimationFrameId && cancelAnimationFrame(vt.requestAnimationFrameId);
  vt.requestAnimationFrameId = requestAnimationFrame(() => {
    setXYLD(event, el);
    const move = Math.abs(vt.currentXY[0] - vt.lastXY[0]) >= vt.opts.tolerance.tap || Math.abs(vt.currentXY[1] - vt.lastXY[1]) >= vt.opts.tolerance.tap;
    if (!vt.touchMoved && move) {
      vt.startXY = vt.currentXY;
      emit(event, el, "dragstart", false);
      addClass(el, "dragstart", true);
    }
    vt.touchMoved = vt.touchMoved || move;
    if (vt.touchMoved) {
      vt.touchHoldTimer && clearTimeout(vt.touchHoldTimer);
      delete vt.touchHoldTimer;
      removeClass(el, "hold");
    }
    if (move) {
      emit(event, el, "rollover", false);
      addClass(el, "rollover");
      vt.multi > 1 && addClass(el, "multi");
      vt.touchRolloverTimer && clearTimeout(vt.touchRolloverTimer);
      vt.touchRolloverTimer = setTimeout(() => (removeClass(el, "rollover"), removeClass(el, "multi")), vt.opts.tolerance.timeout);
    }
    if (Math.abs(vt.currentXY[0] - vt.startXY[0]) >= vt.opts.tolerance.drag || Math.abs(vt.currentXY[1] - vt.startXY[1]) >= vt.opts.tolerance.drag) {
      emit(event, el, "drag", false);
      addClass(el, "drag");
      vt.multi > 1 && addClass(el, "multi");
      vt.touchDragTimer && clearTimeout(vt.touchDragTimer);
      vt.touchDragTimer = setTimeout(() => (removeClass(el, "drag"), removeClass(el, "multi")), vt.opts.tolerance.timeout);
    }
  });
};
const touchcancel = (event) => {
  const el = event.currentTarget;
  clean(el);
};
const touchend = (event) => {
  const el = event.currentTarget;
  const vt = el._vueTouch;
  vt.touchHoldTimer && clearTimeout(vt.touchHoldTimer);
  delete vt.touchHoldTimer;
  removeClass(el, "hold");
  if (!vt.touchMoved) {
    const time = event.timeStamp - vt.touchStartTime;
    if (time < vt.opts.tolerance.hold) {
      if (time >= vt.opts.tolerance.longtap) {
        emit(event, el, "longtap");
        addClass(el, "longtap", true);
        vt.multi > 1 && addClass(el, "multi", true);
      } else {
        emit(event, el, "tap");
        addClass(el, "tap", true);
        vt.multi > 1 && addClass(el, "multi", true);
        if (vt.touchDbltapTimer && isTouchScreenDevice()) {
          emit(event, el, "dbltap");
          addClass(el, "dbltap", true);
          vt.multi > 1 && addClass(el, "multi", true);
        }
        vt.touchDbltapTimer = setTimeout(() => vt.touchDbltapTimer = void 0, vt.opts.tolerance.dbltap);
      }
    }
  } else {
    if (Math.abs(vt.currentXY[0] - vt.startXY[0]) >= vt.opts.tolerance.swipe || Math.abs(vt.currentXY[1] - vt.startXY[1]) >= vt.opts.tolerance.swipe) {
      emit(event, el, "swipe", false);
      addClass(el, "swipe", true);
      vt.multi > 1 && addClass(el, "multi", true);
    }
  }
  vt.touchStarted = false;
  vt.touchMoved = false;
  emit(event, el, "release");
  addClass(el, "release", true);
};
const mouseenter = (event) => {
  const el = event.currentTarget;
  addClass(el, "hover");
  emit(event, el, "hover");
};
const mouseleave = (event) => {
  const el = event.currentTarget;
  const vt = el._vueTouch;
  removeClass(el, "hover");
  vt.touchHoldTimer && clearTimeout(vt.touchHoldTimer);
  delete vt.touchHoldTimer;
  removeClass(el, "hold");
  if (vt.touchMoved) {
    emit(event, el, "swipe");
    addClass(el, "swipe", true);
    vt.multi > 1 && addClass(el, "multi", true);
  }
  vt.touchStarted = false;
  vt.touchMoved = false;
  emit(event, el, "leave");
  addClass(el, "leave", true);
};
const dblclick = (event) => {
  const el = event.currentTarget;
  emit(event, el, "dbltap");
  addClass(el, "dbltap", true);
};
const scroll = (event) => {
  const el = event.currentTarget;
  emit(event, el, "scroll");
};

function debounce(cb, duration = 0) {
  let timer;
  return function() {
    if (!timer) {
      cb.apply(this, arguments);
      timer = setTimeout(() => timer = void 0, duration);
    }
  };
}

const allowsEvents = [
  "hover",
  "press",
  "hold",
  "leave",
  "dbltap",
  "tap",
  "longtap",
  "release",
  "rollover",
  "swipe",
  "drag",
  "dragstart",
  "scroll"
];
const defaultListenerOptions = {
  once: false,
  passive: false,
  capture: false
};
const defineTouch = (options) => {
  const opts = assignOptions(options);
  return {
    beforeMount(el, binding) {
      const isFirstDirective = !("_vueTouch" in el);
      const touchEl = createTouchElement(el, opts);
      const listenerOpts = Object.assign({}, defaultListenerOptions);
      binding.arg = binding.arg || "tap";
      const modifiers = binding.modifiers;
      listenerOpts.capture = modifiers.capture || false;
      listenerOpts.once = modifiers.once || false;
      listenerOpts.passive = modifiers.passive || false;
      Object.keys(modifiers).map((v) => {
        const [arg, param] = v.split(":");
        modifiers[arg] = modifiers[v];
        if (touchEl._vueTouch.opts.tolerance.hasOwnProperty(arg) && param) {
          touchEl._vueTouch.opts.tolerance[arg] = parseInt(param);
        }
      });
      if (modifiers.debounce && typeof binding.value === "function") {
        binding.value = debounce(binding.value, touchEl._vueTouch.opts.tolerance.debounce);
      }
      if (binding.arg === "*") {
        allowsEvents.map((type) => touchEl._vueTouch.callbacks.push(Object.assign({}, binding, { arg: type })));
      } else {
        const [arg, param] = binding.arg.split(":");
        binding.arg = arg;
        if (touchEl._vueTouch.opts.tolerance.hasOwnProperty(arg) && param) {
          touchEl._vueTouch.opts.tolerance[arg] = parseInt(param);
        }
        touchEl._vueTouch.callbacks.push(binding);
      }
      !allowsEvents.includes(binding.arg) && binding.arg !== "*" && console.error(`Allows only ${allowsEvents.join(", ")} modifiers for v-touch`);
      if (isFirstDirective) {
        touchEl.addEventListener("touchstart", touchstart, listenerOpts);
        touchEl.addEventListener("touchmove", touchmove, listenerOpts);
        touchEl.addEventListener("touchcancel", touchcancel, listenerOpts);
        touchEl.addEventListener("touchend", touchend, listenerOpts);
        touchEl.addEventListener("scroll", scroll, listenerOpts);
        if (!isTouchScreenDevice()) {
          touchEl.addEventListener("mousedown", touchstart, listenerOpts);
          touchEl.addEventListener("mousemove", touchmove, listenerOpts);
          touchEl.addEventListener("mouseup", touchend, listenerOpts);
          touchEl.addEventListener("mouseenter", mouseenter, listenerOpts);
          touchEl.addEventListener("mouseleave", mouseleave, listenerOpts);
          touchEl.addEventListener("dblclick", dblclick, listenerOpts);
        }
      }
    },
    unmounted(touchEl) {
      touchEl.removeEventListener("touchstart", touchstart);
      touchEl.removeEventListener("touchmove", touchmove);
      touchEl.removeEventListener("touchcancel", touchcancel);
      touchEl.removeEventListener("touchend", touchend);
      touchEl.removeEventListener("scroll", scroll);
      if ("_vueTouch" in touchEl && touchEl._vueTouch && !isTouchScreenDevice()) {
        touchEl.removeEventListener("mousedown", touchstart);
        touchEl.removeEventListener("mousemove", touchmove);
        touchEl.removeEventListener("mouseup", touchend);
        touchEl.removeEventListener("mouseenter", mouseenter);
        touchEl.removeEventListener("mouseleave", mouseleave);
        touchEl.removeEventListener("dblclick ", dblclick);
      }
    }
  };
};
const touch = defineTouch();
const defineScroll = () => {
  let fn;
  return {
    mounted(el, binding) {
      fn = (event) => {
        typeof binding.value === "function" && binding.value({
          originalEvent: event,
          scroll: [
            scrollX,
            scrollY
          ]
        });
      };
      window.addEventListener("scroll", fn, { passive: true });
      fn();
    },
    unmounted() {
      fn && window.removeEventListener("scroll", fn);
    }
  };
};
const vscroll = defineScroll();
const defineResize = () => {
  let fn;
  return {
    mounted(el, binding) {
      fn = (event) => {
        typeof binding.value === "function" && binding.value({
          originalEvent: event,
          size: [
            innerWidth,
            innerHeight
          ],
          scroll: [
            scrollX,
            scrollY
          ]
        });
      };
      window.addEventListener("resize", fn, { passive: true });
      fn();
    },
    unmounted() {
      fn && window.removeEventListener("resize", fn);
    }
  };
};
const vresize = defineResize();
const defineFinger = () => {
  return {
    async mounted(el, binding) {
      let isFingerprintAvailable = false;
      if ("PublicKeyCredential" in window) {
        isFingerprintAvailable = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
      }
      typeof binding.value === "function" && binding.value({ available: isFingerprintAvailable });
    }
  };
};
const vfinger = defineFinger();
var index = {
  install(app, options) {
    app.directive("touch", defineTouch(options));
    app.directive("touch-classes", {
      beforeMount(el, binding) {
        createTouchElement(el, { classes: binding.value });
      }
    });
    app.directive("touch-tolerance", {
      beforeMount(el, binding) {
        createTouchElement(el, { tolerance: binding.value });
      }
    });
    app.directive("touch-scroll", defineScroll());
    app.directive("touch-resize", defineResize());
    app.directive("touch-finger", defineFinger());
  }
};

exports["default"] = index;
exports.defineTouch = defineTouch;
exports.finger = vfinger;
exports.resize = vresize;
exports.scroll = vscroll;
exports.touch = touch;
